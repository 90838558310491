import React, { useState } from 'react';
import { X, Plus } from 'lucide-react';
import axiosInstance from '../../axiosInstance';

const AdminAddCourse = ({ onAddSuccess, onClose }) => {
    const [courseName, setCourseName] = useState('');
    const [courseCode, setCourseCode] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');

  
    const generateRandomCourseCode = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        const part1 = Array.from({ length: 3 })
            .map(() => letters.charAt(Math.floor(Math.random() * letters.length)))
            .join('');
        const part2 = String(Math.floor(Math.random() * 1000)).padStart(3, '0');
    
        return `${part1}-${part2}`;
    };
    
    const handleGenerateCourseCode = () => {
        setCourseCode(generateRandomCourseCode());
    };

    const handleAddCategory = () => {

        const trimmedCategory = newCategory.trim();
        if (trimmedCategory && !categories.includes(trimmedCategory)) {
            setCategories([...categories, trimmedCategory]);
            setNewCategory('');
        }
    };

    const handleRemoveCategory = (categoryToRemove) => {
        setCategories(categories.filter(category => category !== categoryToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Validation
        if (!courseName.trim() || !courseCode.trim()) {
            setError('Please fill in course name and code');
            return;
        }

        if (categories.length === 0) {
            setError('Please add at least one category');
            return;
        }

        try {
            axiosInstance.post('/courses/addCourse', {
                course_name: courseName,
                course_quiz_code: courseCode,
                course_categories: categories
            });
            
            onAddSuccess();
            onClose();
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to add course');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddCategory();
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
            <div className="bg-white dark:bg-gray-800 rounded-2xl w-full max-w-2xl shadow-2xl transform transition-all ease-in-out duration-300 flex flex-col max-h-[90vh] overflow-hidden">
                
                <div className="flex items-center justify-between p-6 bg-gradient-to-r from-blue-600 to-blue-800 rounded-t-2xl border-b border-blue-700/30">
                    <h3 className="text-xl font-bold text-white tracking-tight">
                        Add New Course
                    </h3>
                    <button
                        onClick={onClose}
                        className="p-2 hover:bg-white/20 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-white/30"
                    >
                        <X className="w-6 h-6 text-white" strokeWidth={2} />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className="p-6 space-y-6">
                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <span className="block sm:inline">{error}</span>
                        </div>
                    )}

                    <div className="group">
                        <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-2">
                            Course Name
                        </h4>
                        <input
                            type="text"
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                            placeholder="Enter course name"
                            className="w-full px-4 py-3 text-lg text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                            required
                        />
                    </div>

                    <div className="group">
                        <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-2">
                            Course Quiz Code
                        </h4>
                        <div className="flex items-center space-x-2">
                            <input
                                type="text"
                                value={courseCode}
                                onChange={(e) => setCourseCode(e.target.value)}
                                placeholder="Course Quiz Code"
                                className="flex-grow px-4 py-3 text-lg text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                                required
                            />
                            <button
                                type="button"
                                onClick={handleGenerateCourseCode}
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                            >
                                Generate
                            </button>
                        </div>
                    </div>

                    <div className="group">
                        <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-2">
                            Course Categories
                        </h4>
                        <div className="flex items-center space-x-2 mb-2">
                            <input
                                type="text"
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder="Enter category"
                                className="flex-grow px-4 py-2 text-lg text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                            />
                            <button
                                type="button"
                                onClick={handleAddCategory}
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                            >
                                <Plus size={20} />
                            </button>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {categories.map((category, index) => (
                                <div 
                                    key={index} 
                                    className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm"
                                >
                                    {category}
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveCategory(category)}
                                        className="ml-2 text-blue-500 hover:text-blue-700"
                                    >
                                        <X size={16} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mt-6 flex justify-end gap-3">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-5 py-2.5 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-5 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200 dark:focus:ring-blue-400"
                        >
                            <Plus size={20} />
                            Add Course
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdminAddCourse; 