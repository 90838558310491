import React from 'react';
import Slate from "./Slate";

const AdminDashboard = () => {
    return (
        <>
            <div className=''>
                <Slate/>
                <div className="max-w-7xl mx-auto py-10 px-20">

                <div className="bg-gradient-to-r from-blue-600 via-blue-800 to-purple-900 p-8 rounded-lg">
                    <h1 className="text-3xl font-bold text-white mb-2">Xplore It Corp</h1>
                    <h2 className="text-4xl font-bold text-white mb-2">Professional IT Training Institute</h2>
                    <p className="text-white/80 text-lg">
                        Best IT Training with Certification in Coimbatore. Best IT Training with placement in Coimbatore. IT courses. Web development!
                    </p>
                </div>
            </div>
            </div>
            
            </>
    )
}

export default AdminDashboard
