import React, { useState, useEffect } from 'react';
import { Plus, Eye, Pencil, Trash2, AlertCircle, X } from 'lucide-react';
import axiosInstance from '../../axiosInstance';
import AdminAddCourse from './AdminAddCourse';
import Slate from '../AdminDashboard/Slate';

const AdminCourse = () => {
    const [course, setCourse] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [alert, setAlert] = useState({ show: false, type: '', message: '' });
    const [openUpdate, setOpenUpdate] = useState(false);
    const [updateData, setUpdateData] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [deletedCourseId, setDeletedCourseId] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null); // Added state for selected course

    const fetchCourse = async () => {
        try {
            const response = await axiosInstance.get('/courses/courseData');
            setCourse(response.data);
        } catch (error) {
            console.error('Error fetching course data:', error);
            setAlert({
                show: true,
                type: 'error',
                message: 'Failed to fetch courses'
            });
        }
    };

    useEffect(() => {
        fetchCourse();
    }, []);

    useEffect(() => {
        const filtered = course.filter(c =>
            c.course_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            c.course_quiz_code.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCourses(filtered);
    }, [course, searchTerm]);


    const handleView = (courseId) => {
        const courseToView = course.find(c => c.course_id === courseId);
        setSelectedCourse(courseToView);
    };

    const handleAddCourses = () => {
        setShowAddModal(true);
    };

    const handleClose = () => {
        fetchCourse();
        setShowAddModal(false);
    };
    

    const handleUpdate = async () => {
        try {
            await axiosInstance.put(`/courses/updateCourses/${updateData.course_id}`, {
                course_name: updateData.course_name,
                course_quiz_code: updateData.course_quiz_code
            });
            setAlert({
                show: true,
                type: 'success',
                message: 'Course updated successfully'
            });
            fetchCourse();
            setOpenUpdate(false);
            setUpdateData(null);
            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
    
        } catch (error) {
            setAlert({
                show: true,
                type: 'error',
                message: error.response?.data?.message || 'Failed to update course'
            });
            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
        }
    };

    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/courses/deleteCourse/${deletedCourseId}`);
            setAlert({
                show: true,
                type: 'success',
                message: 'Course deleted successfully'
            });
            fetchCourse();
            setDeletedCourseId(null); 
        } catch (error) {
            setAlert({
                show: true,
                type: 'error',
                message: error.response?.data?.message || 'Failed to delete course'
            });
        }
    };

    const handleCloseDeleteModal = () => setDeletedCourseId(null); // Close delete modal

    const ViewModal = () => (
        selectedCourse && (
            <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
                <div className="bg-white rounded-xl w-full max-w-2xl shadow-2xl transform transition-all flex flex-col max-h-[90vh]">
                    <div className="flex items-center justify-between p-6 bg-gradient-to-r from-indigo-600 to-indigo-800 rounded-t-xl border-b">
                        <h3 className="text-xl font-semibold text-white tracking-tight capitalize">
                            {selectedCourse.course_name}
                        </h3>
                        <button
                            onClick={() => setSelectedCourse(null)}
                            className="p-2 hover:bg-white/20 rounded-full transition-colors duration-200"
                        >
                            <X className="w-5 h-5 text-white" />
                        </button>
                    </div>

                    <div className="flex-1 overflow-y-auto">
                        <div className="p-6 space-y-6">
                            <div className="group">
                                <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                                    Course Name
                                </h4>
                                <p className="mt-2 text-lg text-gray-700 font-medium capitalize">
                                    {selectedCourse.course_name}
                                </p>
                            </div>
                            <div className="group">
                                <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                                    Course Quiz Code
                                </h4>
                                <p className="mt-2 text-lg text-gray-700 font-medium capitalize">
                                    {selectedCourse.course_quiz_code}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );

    const UpdateModal = () => (
        openUpdate && updateData && (
            <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
                <div className="bg-white dark:bg-gray-800 rounded-2xl w-full max-w-2xl shadow-2xl transform transition-all ease-in-out duration-300 flex flex-col max-h-[90vh] overflow-hidden">
                    <div className="flex items-center justify-between p-6 bg-gradient-to-r from-blue-600 to-blue-800 rounded-t-2xl border-b border-blue-700/30">
                        <h3 className="text-xl font-bold text-white tracking-tight capitalize">
                            Update {updateData.course_name}
                        </h3>
                        <button
                            onClick={() => {
                                setOpenUpdate(false);
                                setUpdateData(null);
                            }}
                            className="p-2 hover:bg-white/20 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-white/30"
                        >
                            <X className="w-6 h-6 text-white" strokeWidth={2} />
                        </button>
                    </div>
    
                    <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-blue-500">
                        <div className="p-6 space-y-6">
                            <div className="group">
                                <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-2">
                                    Course Name
                                </h4>
                                <input
                                    type="text"
                                    value={updateData.course_name}
                                    onChange={(e) => setUpdateData({ ...updateData, course_name: e.target.value })}
                                    className="w-full px-4 py-3 text-lg text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                                />
                            </div>
                            <div className="group">
                                <h4 className="text-sm font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wide mb-2">
                                    Quiz Code
                                </h4>
                                <input
                                    type="text"
                                    value={updateData.course_quiz_code}
                                    onChange={(e) => setUpdateData({ ...updateData, course_quiz_code: e.target.value })}
                                    className="w-full px-4 py-3 text-lg text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                                />
                            </div>
                            <div className="mt-6 flex justify-end gap-3">
                                <button
                                    onClick={() => {
                                        setOpenUpdate(false);
                                        setUpdateData(null);
                                    }}
                                    className="px-5 py-2.5 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleUpdate}
                                    className="px-5 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200 dark:focus:ring-blue-400"
                                >
                                    Update Course
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
    

    return (
        <>
        <Slate/>
            <div className="px-10 py-10 custom-scrollbar">
                <div className="mb-8 flex items-center gap-32 justify-around">
                    <h1 className="text-3xl font-bold text-gray-800">Course Management</h1>
                    <div className="flex items-center gap-4">
                        <input
                            type="text"
                            placeholder="Search courses..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="border rounded p-2 w-64 focus:outline-none"
                        />
                        <button
                            onClick={handleAddCourses}
                            className="text-white bg-[#050708] px-3 py-2 rounded-lg flex items-center gap-1"
                        >
                            <Plus size={20} /> Add Course
                        </button>
                    </div>
                </div>

                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-black text-white">
                                    <th className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">ID</th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Course Name</th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Quiz Code</th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {filteredCourses.map((course) => (
                                    <tr key={course.course_id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                                            {course.course_id}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                            {course.course_name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                            {course.course_quiz_code}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <div className="flex gap-12 justify-center">
                                                <button
                                                    onClick={() => handleView(course.course_id)}
                                                    className="p-1 text-gray-500 hover:text-gray-600 rounded-lg"
                                                >
                                                    <Eye className="w-5 h-5" />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setUpdateData(course);
                                                        setOpenUpdate(true);
                                                    }}
                                                    className="p-1 text-amber-600 hover:bg-amber-50 rounded-lg"
                                                >
                                                    <Pencil className="w-5 h-5" />
                                                </button>
                                                <button
                                                    onClick={() => setDeletedCourseId(course.course_id)}
                                                    className="p-1 text-red-600 hover:bg-red-50 rounded-lg"
                                                >
                                                    <Trash2 className="w-5 h-5" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {alert.show && (
                    <div className={`fixed top-32 right-4 z-50 ${alert.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                        } text-black px-6 py-3 rounded-lg shadow-lg flex items-center gap-2`}>
                        {alert.type === 'success' ? (
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                        ) : (
                            <AlertCircle className="w-5 h-5" />
                        )}
                        <span>{alert.message}</span>
                    </div>
                )}

                <ViewModal />
                <UpdateModal/>
                {deletedCourseId && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
                        <div className="bg-white rounded-xl w-full max-w-xs shadow-2xl p-6 text-center">
                            <h3 className="text-xl font-semibold text-gray-800">Are you sure you want to delete this course?</h3>
                            <div className="mt-6 flex justify-center gap-3">
                                <button
                                    onClick={handleCloseDeleteModal}
                                    className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDelete}
                                    className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
                                >
                                    Confirm Delete
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                
                {showAddModal && (
                <AdminAddCourse
                    onAddSuccess={handleAddCourses}
                    onClose={handleClose}
                />
            )}
                <style jsx>{`
                    .custom-scrollbar {
                        scrollbar-width: none;
                        scrollbar-color: #CBD5E1 transparent;
                    }
                    .custom-scrollbar::-webkit-scrollbar {
                        width: 1px !important;
                    }
                    .custom-scrollbar::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    .custom-scrollbar::-webkit-scrollbar-thumb {
                        background-color: #CBD5E1;
                        border-radius: 20px;
                    }
                `}</style>
            </div>
        </>
    );
};

export default AdminCourse;
