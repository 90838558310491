 import React from 'react'
import AdminLogin from './AdminLogin'
 
 const AdminLoginIndex = () => {
   return (
     <div>
       <AdminLogin/>
     </div>
   )
 }
 
 export default AdminLoginIndex
 