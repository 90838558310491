import React from 'react'
import StudentLogin from './StudentLogin'

const StudentLoginIndex = () => {

  

  return (
    <div>
      <StudentLogin/>
    </div>
  )
}

export default StudentLoginIndex
