import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axiosInstance from "../../axiosInstance";
import { Upload, X } from 'lucide-react';

const ExcelUploadModal = ({ onClose, onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [courseName, setCourseName] = useState([]);
  const [formData, setFormData] = useState({
    course_id: '',
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await axiosInstance.get("/courses/courseData");
        setCourseName(res.data);
      } catch (err) {
        setUploadError("An error occurred while fetching course data.");
      }
    };

    fetchCourses();
  }, []);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    // Read and preview Excel file
    const reader = new FileReader();
    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setPreviewData(jsonData);
    };
    reader.readAsBinaryString(uploadedFile);
  };

  const validateExcelData = (data) => {
    const requiredColumns = [
      "Quiz_name",
      "Quiz_Question",
      "quiz_option",
      "Quiz_Correct_ans",
      "quiz_description",
      "quiz_type",
    ];

    const missingColumns = requiredColumns.filter(
      (col) => !Object.keys(data[0] || {}).includes(col)
    );

    if (missingColumns.length > 0) {
      throw new Error(`Missing required columns: ${missingColumns.join(", ")}`);
    }

    // Process and format the quiz data
    return data.map((row) => {

      let rawOptions;
      if (typeof row.quiz_option === "string") {
        rawOptions = row.quiz_option.split(",").map((option, index) => ({
          id: index,
          text: option.trim(),
        }));
      } else {
        throw new Error(`Invalid format for quiz_option in row: ${JSON.stringify(row)}`);
      }

      let rawCorrectAnswers;
      if (typeof row.Quiz_Correct_ans === "string") {
        rawCorrectAnswers = row.Quiz_Correct_ans.split(",").map((answer) => answer.trim());
      } else if (typeof row.Quiz_Correct_ans === "number") {
        rawCorrectAnswers = [row.Quiz_Correct_ans.toString()];
      } else {
        throw new Error(
          `Invalid format for Quiz_Correct_ans in row: ${JSON.stringify(row)}`
        );
      }

      const correctOptionsWithId = rawCorrectAnswers
        .map((correctText) => {
          const matchingOption = rawOptions.find((opt) => opt.text.toLowerCase() === correctText.toLowerCase());
          return matchingOption
            ? { ...matchingOption, isCorrect: true }
            : null;
        })
        .filter(Boolean);

      return {
        Quiz_name: row.Quiz_name,
        Quiz_Question: row.Quiz_Question,
        quiz_option: rawOptions,
        Quiz_Correct_ans: correctOptionsWithId,
        quiz_description: row.quiz_description || "",
        quiz_type: row.quiz_type,
      };
    });
  };




  const handleUpload = async () => {
    if (!file) {
      setUploadError('Please select a file');
      return;
    }

    try {
      const validatedData = validateExcelData(previewData);

      console.log(validatedData, "validatedData");


      const response = await axiosInstance.post('/quiz/addQuizExcel', {
        course_id: formData.course_id,
        quizzes: validatedData,
      });

      onUploadSuccess(response.data);
      onClose();
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError(
        error.response?.data?.message ||
        error.message ||
        'Failed to upload quizzes'
      );
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-blue-500/20 backdrop-blur-sm animate-in fade-in duration-200">
      <div className="bg-white rounded-xl w-full max-w-2xl shadow-2xl overflow-hidden duration-200 flex flex-col max-h-[90vh]">
        <div className="flex items-center justify-between p-6 bg-gradient-to-r from-blue-800 to-blue-800 rounded-t-xl border-b">
          <h2 className="text-2xl font-bold text-white">Add Quiz Via Excel</h2>
          <button
            onClick={onClose}
            className="p-2 text-white hover:bg-white/20 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {uploadError && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                {uploadError}
              </div>
            )}

        <div className="overflow-y-auto no-scrollbar flex-1">
          <div  className="p-6 space-y-6 overflow-y-auto overflow-hidden">
            <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">Course</label>
                          <select
                            value={formData.course_id}
                            onChange={(e) => handleInputChange('course_id', e.target.value)}
                            className="w-full rounded-lg border-gray-300 border p-2.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          >
                            <option value="">Select Course</option>
                            {courseName.map((course) => (
                              <option key={course.course_id} value={course.course_id}>
                                {course.course_name}
                              </option>
                            ))}
                          </select>
                        </div>
            
                        <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
                          <input
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            className="hidden"
                            id="excelUpload"
                          />
                          <label
                            htmlFor="excelUpload"
                            className="cursor-pointer flex flex-col items-center"
                          >
                            <Upload className="w-12 h-12 text-gray-400 mb-4" />
                            <p className="text-gray-600">
                              {file
                                ? `Selected File: ${file.name}`
                                : 'Click to upload Excel file (.xlsx, .xls)'}
                            </p>
                          </label>
                        </div>
            
                        {previewData.length > 0 && (
                          <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Preview Quizzes</h3>
                            <div className="max-h-64 overflow-y-auto border border-gray-300 rounded-lg">
                              <table className="w-full border-collapse">
                                <thead className="sticky top-0 bg-gray-100">
                                  <tr>
                                    {Object.keys(previewData[0]).map((header) => (
                                      <th key={header} className="border p-2 text-sm text-left">
                                        {header}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {previewData.map((row, rowIndex) => (
                                    <tr key={rowIndex} className="border hover:bg-gray-50">
                                      {Object.values(row).map((value, cellIndex) => (
                                        <td
                                          key={cellIndex}
                                          className="border p-2 text-xs break-words whitespace-nowrap overflow-hidden text-ellipsis max-w-xs"
                                          title={typeof value === 'object' ? JSON.stringify(value) : value}
                                        >
                                          {typeof value === 'object' ? JSON.stringify(value) : value}
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            {previewData.length > 5 && (
                              <p className="text-sm text-gray-500">
                                Scroll to view more rows.
                              </p>
                            )}
                          </div>
                        )}

            <div className="flex-none flex items-center justify-end gap-x-4 pt-6">
            <button
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleUpload}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                disabled={!file}
              >
                Upload Quizzes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
};

export default ExcelUploadModal;
