import React, { useState, useEffect } from "react";
import {
  Eye,
  Pencil,
  Plus,
  Trash2,
  Download,
  X,
  AlertCircle,
  Upload,
} from "lucide-react"
import axiosInstance from "../../axiosInstance";
import AdminUpdateQuiz from "./AdminUpdateQuiz";
import AdminAddQuiz from "./AdminAddQuiz";
import Slate from "../AdminDashboard/Slate";
import ExcelUploadModal from "./ExcelUploadModal";

const AdminQuiz = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [deletedQuizId, setDeletedQuizId] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showExcelUploadModal, setShowExcelUploadModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "success",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("all");

  const handleExcelUploadSuccess = (uploadedQuizzes) => {
  
    fetchQuizzes();
    showAlert(`${uploadedQuizzes.length} Quizzes uploaded successfully`);
  };

  const formatQuizOptions = (options) => {
    try {
      if (typeof options === 'string' && options.trim()) {
        const optionArray = JSON.parse(options);
        return optionArray.map(opt => opt.text).join(', ');
      } else if (Array.isArray(options)) {
        return options.map(opt => opt.text).join(', ');
      }
      return '';
    } catch (error) {
      console.error('Error formatting quiz options:', error);
      return '';
    }
  };

  const formatCorrectAnswers = (answers) => {
    try {
      if (typeof answers === 'string' && answers.trim()) {
        const answerArray = JSON.parse(answers);
        return Array.isArray(answerArray)
          ? answerArray.map(ans => ans.text).join(', ')
          : answerArray.text;
      }
      return '';
    } catch (error) {
      console.error('Error formatting correct answers:', error);
      return '';
    }
  };

  const fetchCourses = async () => {
    try {
      const res = await axiosInstance.get("/courses/CourseData");
      setCourses(res.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };



  const showAlert = (message, type = "success") => {
    setAlert({ show: true, message, type });
    setTimeout(
      () => setAlert({ show: false, message: "", type: "success" }),
      3000
    );
  };

  const fetchQuizzes = async () => {
    try {
      const response = await axiosInstance.get("/quiz/QuizData");
      setQuizzes(response.data);
    } catch (error) {
      showAlert("Failed to fetch quizzes", "error");
    }
  };

  useEffect(() => {
    fetchQuizzes();
    fetchCourses();
  }, []);

  const handleView = async (quizId) => {
    try {
      const response = await axiosInstance.get(`/quiz/ViewQuiz/${quizId}`);
      if (response.data && response.data.length > 0) {
        setSelectedTopic(response.data[0]);
        setShowViewModal(true);
      } else {
        showAlert("Quiz details not found", "error");
      }
    } catch (error) {
      showAlert("Failed to fetch quiz details", "error");
    }
  };

  const handleDelete = async () => {
    if (!deletedQuizId) return;

    try {
      await axiosInstance.delete(`/quiz/deleteQuiz/${deletedQuizId}`);
      setQuizzes((prev) =>
        prev.filter((quiz) => quiz.Quiz_id !== deletedQuizId)
      );
      showAlert("Quiz deleted successfully");
      setDeletedQuizId(null);
    } catch (error) {
      showAlert("Failed to delete quiz", "error");
    }
  };

  const handleUpdate = () => {
    fetchQuizzes();
    setShowUpdateModal(false);
    showAlert("Quiz updated successfully");
  };

  const handleAddSuccess = () => {
    fetchQuizzes();
    setShowAddModal(false);
    showAlert("Quiz added successfully");
  };

  const filteredQuizzes = quizzes.filter((quiz) => {
    const matchesSearch =
      quiz.Quiz_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quiz.Quiz_Question?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCourse =
      selectedCourse === "all" || quiz.course_id === parseInt(selectedCourse);

    return matchesSearch && matchesCourse;
  });

  const ViewModal = () =>
    showViewModal &&
    selectedTopic && (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-sm">
        <div className="bg-white rounded-xl w-full max-w-2xl shadow-2xl transform transition-all flex flex-col max-h-[90vh]">
          <div className="flex items-center justify-between p-6 bg-gradient-to-r from-blue-800 to-blue-800 rounded-t-xl border-b">
            <h3 className="text-xl font-semibold text-white tracking-tight capitalize">
              {selectedTopic.Quiz_name}
            </h3>
            <button
              onClick={() => {
                setShowViewModal(false);
                setSelectedTopic(null);
              }}
              className="p-2 hover:bg-white/20 rounded-full transition-colors duration-200"
            >
              <X className="w-5 h-5 text-white" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Course
              </h4>
              <p className="mt-2 text-lg text-gray-700 font-medium capitalize">
                {courses.find((course) => course.course_id === selectedTopic.course_id)?.course_name}
              </p>
            </div>

            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Quiz Type
              </h4>
              <p className="mt-2 text-lg text-gray-700 font-medium capitalize">
                {selectedTopic.quiz_type}
              </p>
            </div>

            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Quiz Name
              </h4>
              <p className="mt-2 text-lg text-gray-700 font-medium capitalize">
                {selectedTopic.Quiz_name}
              </p>
            </div>

            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Quiz Content
              </h4>
              <p className="mt-2 text-gray-700 leading-relaxed text-justify capitalize">
                {selectedTopic.Quiz_Question}
              </p>
            </div>

            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Quiz Options
              </h4>
              <div className="mt-2 space-y-2">
                {selectedTopic.quiz_option &&
                  (Array.isArray(selectedTopic.quiz_option)
                    ? selectedTopic.quiz_option
                    : JSON.parse(selectedTopic.quiz_option)
                  ).map((option, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <span className="w-6 h-6 flex items-center justify-center bg-gray-100 rounded-full text-sm font-medium">
                        {index + 1}
                      </span>
                      <p className="text-gray-700">{option.text}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Correct Answer
              </h4>
              <p className="mt-2 text-gray-700 leading-relaxed">
                {formatCorrectAnswers(selectedTopic.Quiz_Correct_ans)}
              </p>
            </div>

            <div className="group">
              <h4 className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                Answer Description
              </h4>
              <p className="mt-2 text-gray-700 leading-relaxed text-justify">
                {selectedTopic.quiz_description}
              </p>
            </div>
          </div>
        </div>
      </div>
    );

  const DeleteModal = () =>
    deletedQuizId && (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50">
        <div className="bg-white rounded-lg w-full max-w-md">
          <div className="p-6">
            <div className="flex items-center gap-2 text-red-600 mb-4">
              <AlertCircle className="w-6 h-6" />
              <h3 className="text-xl font-semibold mt-2">Confirm Deletion</h3>
            </div>
            <p className="text-gray-600">
              Are you sure you want to delete this quiz? This action cannot be
              undone.
            </p>
            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={() => setDeletedQuizId(null)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Delete Quiz
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <Slate />
      <div className="px-10 py-10" >
        <div className="mb-8 flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold text-gray-800">
              Quiz Management
            </h1>
          </div>

          <div className="flex items-center gap-6">
            <div className="flex gap-4">
              <div className="w-48">
                <label
                  htmlFor="courseFilter"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Filter by Course
                </label>
                <select
                  id="courseFilter"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  className="w-full border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="all">All Courses</option>
                  {courses.map((c) => (
                    <option key={c.course_id} value={c.course_id}>
                      {c.course_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-64">
                <label
                  htmlFor="topicSearch"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Search Quizzes
                </label>
                <input
                  id="topicSearch"
                  type="text"
                  placeholder="Search by quiz name or content..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="flex items-end">
              <button
                onClick={() => setShowAddModal(true)}
                className="text-white bg-[#050708] px-4 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors h-10"
              >
                <Plus size={20} /> Add Quiz
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="mb-4 text-sm text-gray-600">
            Showing {filteredQuizzes.length}{" "}
            {filteredQuizzes.length === 1 ? "quiz" : "quizzes"}
          </div>
          <div className="flex gap-3">
            <button 
              onClick={() => setShowExcelUploadModal(true)}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 mb-3 flex items-center gap-2"
            >
              <Upload className="w-5 h-5" /> Upload Excel
            </button>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-black text-white">
                  {[
                    "ID",
                    "Course Name",
                    "Quiz Type",
                    "Quiz Name",
                    "Quiz Question",
                    "Quiz Options",
                    "Correct Answer",
                    "Actions",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-center text-xs font-medium text-white uppercase"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredQuizzes.map((quiz) => (
                  <tr key={quiz.Quiz_id} className="hover:bg-gray-50">
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                      {quiz.Quiz_id}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 font-xl capitalize">
                      {courses.find((c) => c.course_id === quiz.course_id)?.course_name}
                    </td>

                    <td className="px-6 py-4 text-sm capitalize text-gray-500 max-w-xs">
                      <div className="line-clamp-2">{quiz.quiz_type}</div>
                    </td>
                    <td className="px-6 py-4 text-sm capitalize text-gray-500 max-w-xs">
                      <div className="line-clamp-2">{quiz.Quiz_name}</div>
                    </td>
                    <td className="px-6 py-4 text-sm capitalize text-gray-500 max-w-xs">
                      <div className="line-clamp-2">{quiz.Quiz_Question}</div>
                    </td>
                    <td className="px-6 py-4 text-sm capitalize text-gray-500 max-w-xs">
                      <div className="line-clamp-2">{formatQuizOptions(quiz.quiz_option)}</div>
                    </td>
                    <td className="px-6 py-4 text-sm capitalize text-gray-500 max-w-xs">
                      <div className="line-clamp-2">{formatCorrectAnswers(quiz.Quiz_Correct_ans)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex gap-5 justify-center">
                        <button
                          onClick={() => handleView(quiz.Quiz_id)}
                          className="p-1 text-gray-500 hover:text-gray-600 rounded-lg"
                        >
                          <Eye className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => {
                            setUpdateData(quiz);
                            setShowUpdateModal(true);
                          }}
                          className="p-1 text-amber-600 hover:bg-amber-50 rounded-lg"
                        >
                          <Pencil className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => setDeletedQuizId(quiz.Quiz_id)}
                          className="p-1 text-red-600 hover:bg-red-50 rounded-lg"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {alert.show && (
          <div
            className={`fixed top-32 right-4 z-50 ${alert.type === "success" ? "bg-green-500" : "bg-red-500"
              } text-white px-6 py-3 rounded-lg shadow-lg flex items-center gap-2`}
          >
            {alert.type === "success" ? (
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            ) : (
              <AlertCircle className="w-5 h-5" />
            )}
            <span>{alert.message}</span>
          </div>
        )}

        <ViewModal />
        <DeleteModal />

        {showUpdateModal && (
          <AdminUpdateQuiz
            data={updateData}
            onUpdateSuccess={handleUpdate}
            onClose={() => setShowUpdateModal(false)}
          />
        )}

        {showAddModal && (
          <AdminAddQuiz
            onAddSuccess={handleAddSuccess}
            onClose={() => setShowAddModal(false)}
          />
        )}

        {showExcelUploadModal && (
          <ExcelUploadModal 
            onClose={() => setShowExcelUploadModal(false)}
            onUploadSuccess={handleExcelUploadSuccess}
          />
        )}
      </div>
    </>
  );
};

export default AdminQuiz;