import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext'
import AdminDashboardIndex from './Admin Master/AdminDashboard/AdminDashboardIndex';
import AdminCourseIndex from './Admin Master/AdminCourse/AdminCourseIndex';
import AdminQuizIndex from './Admin Master/AdminQuiz/AdminQuizIndex';
import StudentHeroIndex from './Studen Master/StudenLanding/StudentHeroIndex';
import StudentLoginIndex from './Studen Master/StudentLogin/StudentLoginIndex';
import StudentMainIndex from './Studen Master/StudenLoginIndex/StudentMainIndex';
import StudentQuizIndex from './Studen Master/StudentQuiz/StudentQuizIndex';
import StudentResultIndex from './Studen Master/StudentResultIndex/StudentResultIndex';
import StudentFeedBackIndex from './Studen Master/StudentFeedBackForm/StudentFeedBackIndex';
import StudentQuizResultIndex from './Studen Master/StudentQuizResult/StudentQuizResultIndex';
import AdminLoginIndex from './Admin Master/AdminLogin/AdminLoginIndex';
import AdminProtectedRoute from './AdminProtectedRoute';
import NotFound from './NotFound';

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<StudentHeroIndex />} />

          <Route element={<AdminProtectedRoute />}>
            <Route path="/admin" element={<AdminDashboardIndex />} />
            <Route path="/Dashboard" element={<AdminDashboardIndex />} />
            <Route path="/course" element={<AdminCourseIndex />} />
            <Route path="/quiz" element={<AdminQuizIndex />} />
          </Route>

          <Route path="*" element={<NotFound />} />

          <Route path="/adminlogin" element={<AdminLoginIndex />} />




          <Route path="/login" element={<StudentLoginIndex />} />

          <Route path="/landingIndex" element={<StudentMainIndex />} />

          <Route path="/:coursename/quiz" element={<StudentQuizIndex />} />

          <Route path="/:coursename/student-result" element={<StudentResultIndex />} />

          <Route path="/:coursename/feedbackform" element={<StudentFeedBackIndex />} />

          <Route path="/:coursename/viewquiz-results" element={<StudentQuizResultIndex />} />



        </Routes>

      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
