import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  GraduationCap,
  HelpCircle,
  LogOut,
  ChevronDown,
  Settings,
  User,
  Bell,
  Trash,
  Layout,
} from 'lucide-react';

const AdminDashboardSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const [isOpen] = useState(true);
  
  const currentPath = location.pathname;
  
  const menuItems = [
    { icon: <Layout size={20} />, label: 'Dashboard', path: '/dashboard' },
    { icon: <GraduationCap size={20} />, label: 'Course', path: '/course' },
    { icon: <HelpCircle size={20} />, label: 'Quiz', path: '/Quiz' },
    { icon: <Trash size={20} />, label: 'Trash', path: '/trash' },
  ];
  
  const bottomMenuItems = [
    { 
      icon: <HelpCircle size={20} />, 
      label: 'Help & Support',
      path: '/help',
    },
    { 
      icon: <LogOut size={20} />, 
      label: 'Sign Out', 
      className: 'text-red-600 hover:bg-red-50',
      path: '/',
    },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleSignOut = () => {
    navigate('/');
  };

  return (
    <div className="relative h-screen ">
     
      <aside
        className={`fixed left-0 h-screen bg-white transition-all duration-300 ease-in-out border 
          ${isOpen ? 'w-full md:w-72 lg:w-72' : 'w-0 -translate-x-full'} 
          lg:translate-x-0 lg:w-72`}
      >
        <div className="flex flex-col h-full">
          {/* Logo Section */}
          <div className="p-6 border-b border-gray-100">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-lg bg-gray-950 flex items-center justify-center transform transition-all duration-300 hover:rotate-12">
                <span className="text-white font-bold">X</span>
              </div>
              <span className="text-lg font-semibold text-gray-900">Xplore It Corp</span>
            </div>
          </div>
          <div className="px-4 py-3 border-b border-gray-100">
            <div 
              className="flex items-center justify-between cursor-pointer rounded-xl p-2 hover:bg-gray-50 transition-all duration-300 hover:shadow-md transform hover:translate-x-1"
              onClick={() => setIsProfileExpanded(!isProfileExpanded)}
            >
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-gradient-to-br from-indigo-500 to-purple-500 overflow-hidden ring-2 ring-white shadow-lg">
                  <img 
                    src="/api/placeholder/40/40"
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-900">Admin Panel</h3>
                  <p className="text-xs text-gray-500">Admin</p>
                </div>
              </div>
              <ChevronDown 
                size={16} 
                className={`text-gray-400 transition-transform duration-300 ${
                  isProfileExpanded ? 'rotate-180' : ''
                }`}
              />
            </div>
            <div 
              className={`mt-1 space-y-1 transition-all duration-300 ease-in-out overflow-hidden
                ${isProfileExpanded ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <button 
                onClick={() => handleNavigation('/profile')}
                className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-50 transition-all duration-300 hover:translate-x-2 group"
              >
                <User size={16} className="text-gray-400 group-hover:text-indigo-600 transition-colors duration-300" />
                <span className="group-hover:text-indigo-600">View Profile</span>
              </button>
              <button 
                onClick={() => handleNavigation('/settings')}
                className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-50 transition-all duration-300 hover:translate-x-2 group"
              >
                <Settings size={16} className="text-gray-400 group-hover:text-indigo-600 transition-colors duration-300" />
                <span className="group-hover:text-indigo-600">Settings</span>
              </button>
              <button 
                onClick={() => handleNavigation('/notifications')}
                className="w-full flex items-center gap-2 px-3 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-50 transition-all duration-300 hover:translate-x-2 group"
              >
                <Bell size={16} className="text-gray-400 group-hover:text-indigo-600 transition-colors duration-300" />
                <span className="group-hover:text-indigo-600">Notifications</span>
              </button>
            </div>
          </div>

          {/* Main Navigation */}
          <nav className="flex-1 px-4 py-2 overflow-y-auto custom-scrollbar">
            <div className="">
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  onClick={() => handleNavigation(item.path)}
                  className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-300 ease-in-out
                    transform hover:translate-x-1 hover:shadow-md
                    ${currentPath === item.path
                      ? 'bg-indigo-50 text-indigo-600 font-medium shadow-sm' 
                      : 'text-gray-600 hover:bg-gray-50'
                    } group`}
                >
                  <span className={`transition-colors duration-300 ${currentPath === item.path ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'}`}>
                    {item.icon}
                  </span>
                  <span className="text-sm group-hover:text-indigo-600">{item.label}</span>
                </button>
              ))}
            </div>
          </nav>

          {/* Bottom Menu */}
          <div className="p-4 border-t border-gray-100">
            <div className="space-y-1">
              {bottomMenuItems.map((item, index) => (
                <button
                  key={index}
                  onClick={() => item.label === 'Sign Out' ? handleSignOut() : handleNavigation(item.path)}
                  className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-300
                    transform hover:translate-x-1 hover:shadow-md
                    ${item.className || 'text-gray-600 hover:bg-gray-50'} group`}
                >
                  <span className={`transition-colors duration-300 ${
                    item.className?.includes('text-red-600') 
                      ? 'text-red-400' 
                      : 'text-gray-400 group-hover:text-indigo-600'
                  }`}>
                    {item.icon}
                  </span>
                  <span className="text-sm">{item.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </aside>

      <style jsx>{`
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: #CBD5E1 transparent;
        }
        .custom-scrollbar::-webkit-scrollbar {
          width: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: transparent;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: #CBD5E1;
          border-radius: 20px;
        }
      `}</style>
    </div>
  );
};

export default AdminDashboardSideBar ;