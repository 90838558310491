import React, { useState, useEffect } from 'react';
import { X, AlertCircle, Loader2, PlusCircle, Trash2 } from 'lucide-react';
import axiosInstance from '../../axiosInstance';

const AdminUpdateQuiz = ({ data, onClose, onUpdateSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [courseName, setCourseName] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [quizData, setQuizData] = useState({
    course_id: "",
    quiz_type: "",
    Quiz_name: "",
    Quiz_Question: "",
    Quiz_options: [],
    Quiz_Correct_ans: {},
    quiz_description: "",
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await axiosInstance("/courses/CourseData");
        setCourseName(res.data);
      } catch {
        setError("Failed to fetch courses.");
      }
    };

    fetchCourses();

    if (data) {
      try {
        const initialOptions =  JSON.parse(data.quiz_option) || [];
        let correctAnswer = {};

        try {
          const parsedCorrectAns = JSON.parse(data.Quiz_Correct_ans);
          correctAnswer = Array.isArray(parsedCorrectAns) ? parsedCorrectAns[0] : parsedCorrectAns;
        } catch (e) {
          console.error("Error parsing Quiz_Correct_ans:", e);
        }

        setQuizData({
          course_id: data.course_id,
          quiz_type: data.quiz_type,
          Quiz_name: data.Quiz_name,
          Quiz_Question: data.Quiz_Question,
          Quiz_options: initialOptions,
          Quiz_Correct_ans: correctAnswer,
          quiz_description: data.quiz_description,
        });
      } catch (err) {
        console.error("Error setting quiz data:", err);
        setError("Error loading quiz data");
      }
    }
  }, [data]);

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...quizData.Quiz_options];
    updatedOptions[index] = { id: index, text: value.trim() };

    setQuizData((prev) => ({
      ...prev,
      Quiz_options: updatedOptions,
      Quiz_Correct_ans: prev.Quiz_Correct_ans.id === index
        ? { id: index, text: value.trim() }
        : prev.Quiz_Correct_ans,
    }));
  };

  const addOption = () => {
    setQuizData((prev) => ({
      ...prev,
      Quiz_options: [...prev.Quiz_options, { id: prev.Quiz_options.length, text: "" }],
    }));
  };

  const removeOption = (index) => {
    setQuizData((prev) => {
      const newOptions = prev.Quiz_options.filter((_, i) => i !== index)
        .map((opt, i) => ({ ...opt, id: i }));

      return {
        ...prev,
        Quiz_options: newOptions,
        Quiz_Correct_ans: prev.Quiz_Correct_ans.id === index ? {} : prev.Quiz_Correct_ans,
      };
    });
  };

  const handleCorrectAnswerChange = (option) => {
    setQuizData((prev) => ({
      ...prev,
      Quiz_Correct_ans: option,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");  
    setSuccess("");
  
    try {
      if (!quizData.course_id || !quizData.Quiz_name ||
        !quizData.Quiz_Question || quizData.Quiz_options.length === 0 ||
        !quizData.Quiz_Correct_ans.text || !quizData.quiz_description || !quizData.quiz_type) {
        throw new Error("Please fill in all required fields");
      }
  
      const submissionData = {
        ...quizData,
        quiz_option: quizData.Quiz_options, 
        Quiz_Correct_ans: quizData.Quiz_Correct_ans, 
      };
  
      const response = await axiosInstance.put(
        `/quiz/updateQuiz/${data.Quiz_id}`,
        submissionData,
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.status !== 200) throw new Error("Failed to update quiz");
  
      setSuccess("Quiz updated successfully");
      if (onUpdateSuccess) onUpdateSuccess();
  
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      console.error("Error occurred:", err);
      setError(err.message || "Failed to update quiz");
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-blue-500/20 backdrop-blur-sm">
      <div className="bg-white rounded-xl w-full max-w-2xl shadow-2xl flex flex-col max-h-[90vh]">
        <div className="flex items-center justify-between p-6 bg-gradient-to-r from-blue-600 to-blue-600 rounded-t-xl border-b">
          <h3 className="text-xl font-semibold text-white">Update Quiz</h3>
          <button onClick={onClose} className="p-2 hover:bg-white/20 rounded-full">
            <X className="w-5 h-5 text-white" />
          </button>
        </div>
        {loading && <Loader2 className="w-8 h-8 animate-spin text-blue-500" />}
        {error && (
          <div className="p-4 bg-red-100 text-red-700 flex items-center gap-2">
            <AlertCircle className="w-5 h-5" /> {error}
          </div>
        )}
        {success && (
          <div className="p-4 bg-green-100 text-green-700">{success}</div>
        )}
        <div className="overflow-y-auto flex-1">
          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Course</label>
              <select
                value={quizData.course_id}
                onChange={(e) => setQuizData({ ...quizData, course_id: e.target.value })}
                className="w-full rounded-lg border-gray-300 border p-2.5 focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Course</option>
                {courseName.map((course) => (
                  <option key={course.course_id} value={course.course_id}>
                    {course.course_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Quiz Difficulty</label>
              <select
                value={quizData.quiz_type}
                onChange={(e) => setQuizData({ ...quizData, quiz_type: e.target.value })}
                className="w-full rounded-lg border-gray-300 border p-2.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select Difficulty</option>
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Quiz Name</label>
              <input
                type="text"
                value={quizData.Quiz_name}
                onChange={(e) => setQuizData({ ...quizData, Quiz_name: e.target.value })}
                className="w-full rounded-lg border-gray-300 border p-2.5 focus:ring-2 focus:ring-blue-500"
                placeholder="Enter quiz name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Question</label>
              <input
                type="text"
                value={quizData.Quiz_Question}
                onChange={(e) => setQuizData({ ...quizData, Quiz_Question: e.target.value })}
                className="w-full rounded-lg border-gray-300 border p-2.5 focus:ring-2 focus:ring-blue-500"
                placeholder="Enter quiz question"
              />
            </div>

            <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">Options</label>
              <div className="space-y-3">
                {quizData.Quiz_options.map((option, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-4 p-3 rounded-lg border border-gray-200 hover:border-blue-400 transition-colors bg-gray-50"
                  >
                    <input
                      type="text"
                      value={option.text}
                      onChange={(e) => handleOptionChange(index, e.target.value)}
                      className="flex-1 bg-transparent outline-none border-none"
                      placeholder="Enter option text"
                    />
                    <label className="flex items-center gap-2 cursor-pointer">
                      <input
                        type="radio"
                        name="correct_answer"
                        checked={quizData.Quiz_Correct_ans.id === option.id}
                        onChange={() => handleCorrectAnswerChange(option)}
                        className="w-4 h-4 text-blue-600"
                      />
                      <span className="text-sm text-gray-600">Correct</span>
                    </label>
                    {quizData.Quiz_options.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeOption(index)}
                        className="p-1.5 text-red-600 hover:bg-red-50 rounded-lg"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addOption}
                  className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-500 font-medium"
                >
                  <PlusCircle className="w-4 h-4" />
                  Add Option
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Quiz Description
              </label>
              <textarea
                value={quizData.quiz_description}
                onChange={(e) => setQuizData({ ...quizData, quiz_description: e.target.value })}
                className="w-full rounded-lg border-gray-300 border p-2.5 focus:ring-2 focus:ring-blue-500"
                placeholder="Enter quiz description"
                rows={4}
              />
            </div>

            <div className="flex justify-end gap-x-4 pt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2.5 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="bg-gradient-to-r from-blue-800 to-blue-800 text-white py-2.5 px-4 rounded-lg hover:opacity-90 disabled:opacity-50"
              >
                {loading ? (
                  <span className="inline-flex items-center gap-2">
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Updating...
                  </span>
                ) : (
                  'Update Quiz'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminUpdateQuiz;