import React, { useState } from 'react'

import { Menu, X } from 'lucide-react';
import AdminQuiz from './AdminQuiz';
import AdminDashboardSideBar from '../AdminDashboard/AdminDashboardSideBar';

const AdminQuizIndex = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
  

    return (
      <div className="relative min-h-screen flex">
        <button 
          onClick={() => setSidebarOpen(!isSidebarOpen)}
          className="fixed top-4 left-4 z-50 p-2 rounded-md bg-white shadow-md md:hidden"
        >
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
  
        <div className={` 
          fixed top-0 left-0 h-full w-64 bg-white shadow-lg transition-transform duration-300 ease-in-out z-40
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0 md:static md:w-auto
        `}>
          <div className="h-full">
            <AdminDashboardSideBar />
          </div>
        </div>
  
  
        {isSidebarOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
            onClick={() => setSidebarOpen(false)}
          />
        )}
  
        <div className={` 
          flex-1 transition-all duration-300 ease-in-out 
          ${isSidebarOpen ? 'ml-0' : 'ml-0'}
          md:ml-64
        `}>
          <div className="h-screen overflow-y-auto">
            <AdminQuiz />
          </div>
        </div>
      </div>
    );
}

export default AdminQuizIndex